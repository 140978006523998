<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";

import LottieLoader from "../../../components/LottieLoader";
import BaseUrl from '../../../components/constants/index'
import ToastAlertCOnfig from '../../../../ToastConfig'
import ToastConfigAlert from "../../../../ToastConfig";
/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      phone: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      showLoader : false,
      showPassword : false
    };
  },
  components: {
    Auth,
    LottieLoader,
  },

  created() {

  },
  methods: {
   async UserLoginFetch() {

     // let validated_username = await this.$validator.validate('phone', this.phone);
     // let validated_password = await this.$validator.validate('password', this.password);
     //
     // if(validated_username && validated_password) {

      try {

        const userData = {
          phoneNumber : this.phone,
          password : this.password,
          countryCode : "GH"
        }
        this.showLoader = true;
        const response = await fetch(`${BaseUrl.URL}/auth/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });
        this.showLoader = false;


        if (response.ok) {
          const responseData = await response.data;
          console.log('Response data:', responseData);

          const encryptedData =  BaseUrl.Encryption(response?.data?.payload)
          sessionStorage.setItem("userData",encryptedData)
         // console.log('Response data:', responseData?.data?.message);
          window.location.href = "/"
          // You can update your Vue component's state or perform other actions here
        } else {
          this.$bvToast.toast(response.data.message,ToastAlertCOnfig)
           console.error('Error:', response.statusText);
        }

      }catch (e) {
         console.log(" data : ", e)
        this.$bvToast.toast(e.message, ToastAlertCOnfig)
      }


     //  }


    },
    UserLogin() { 
      this.$validator.validateAll().then((result) => {
        if (result) {
      
          const userData = {
             phoneNumber : this.phone,
             password : this.password,
             countryCode : "GH"
          }

              this.showLoader = true;
              this.$http.post(`${BaseUrl.URL}/auth/login`, userData, {}).then((response) => {
              this.showLoader = false;

              if(!response.data.status) {
                this.$bvToast.toast(response.data.message,ToastAlertCOnfig)
                return;
              }
             // ENCRYPT DATA
            const encryptedData =  BaseUrl.Encryption(response.data.payload)

            sessionStorage.setItem("userData",encryptedData)

             window.location.href = "/"
          }).catch((error) => {
            this.showLoader = false;
            this.$bvToast.toast(error.message, ToastAlertCOnfig)
                console.log(" errors : ", error)
          })

        }
      })

    }
   
  },
};
</script>

<template>
  <Auth>
        <div>
             <lottie-loader :loading="showLoader" />
        </div>
    <div class="row justify-content-center content">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/logo-dark.svg"
                      alt=""
                      height="50"
                    />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/logo-light.png"
                      alt=""
                      height="50"
                    />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                Enter your phone number and password to access admin panel.
              </p>
            </div>

            <form action="#" @submit.prevent="UserLogin">
             
              <div class="form-group mb-3">
                <label for="phone">Phone number</label>
                <input
                  class="form-control"
                  v-model="phone"
                  type="text"
                  id="phone"
                  placeholder="Enter your phone number"
                  v-validate="'required'"
                  :class="{'is-invalid': errors.has('phone') }"
                />
                <span v-show="errors.has('phone')" class="help text-danger">{{ errors.first('phone') }}</span>
              </div>

              <div class="form-group mb-3">
                <label for="password">Password</label>
                  <div class="input-group input-group-merge">
                    <input
                            v-model="password"
                            :type="[showPassword ? 'text' : 'password']"
                            id="password"
                            name="password"
                            class="form-control"
                            v-validate="'required'"
                            placeholder="Enter your password"
                            :class="{'is-invalid': errors.has('password') }"
                    />

                    <div class="input-group-append" data-password="false">

                       <span class="input-group-text"  @click="showPassword = !showPassword">
                               <i class="fa" :class="[showPassword ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
                        </span>

                    </div>

                  </div>
                  <span v-show="errors.has('password')" class="help text-danger">{{ errors.first('password') }}</span>
                </div>


              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Log In
                </button>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">

          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>
